﻿.ShopOpeningDatesContainer {
    text-align: center;

    .ShopOpenLabel {
        color: green;
    }

    .ShopClosedLabel {
        color: red;
    }
}

body.show-product-select .PublisherContainer .check-favorite {
    display: initial;
}

body.publish-mode.show-product-select .PublisherContainer .check-favorite {
    display: none;
}

/* Centering */
.is-orderbook.body-out #login .login-customer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 260px;
}

.first-orderbook-help-container {
    margin-bottom: 1em;
}

h4.orderbook-config-not-shown-here {
    padding-left:15px;
}